import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import Loading from '../components/Loading';
import ContentSection from '../components/ContentSection';
import Layout from '../components/Layout';
import ButtonLink from '../components/ButtonLink';
import Image from "gatsby-image";

export default function WebPage(){
  
    let data = useStaticQuery(graphql`
    query AllSitesQuery {
      allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/sites/"}}) {
        edges {
          node {
            html
            frontmatter {
              sites {
                title
                github
                demo
                meta {
                  langs
                }
              }
              photos {
                childImageSharp {
                  fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                    aspectRatio
                  }
                }
              }
            }
          }
        }
      }
    }
    

    `);
    data = data.allMarkdownRemark.edges
    return(
        
    <Layout title="Strony">
        {!data ? <Loading /> : data.map((site,i)=>{
          const {frontmatter} = site.node
          const {title, meta, github, demo} = frontmatter.sites
                return <ContentSection key={i} 
                title={title} 
                meta={meta} 
                description={
                  <>
                <div dangerouslySetInnerHTML={{ __html: site.node.html }} className="description-content"></div>
                <ButtonLink href={github}>kod na githubie</ButtonLink> 
                {demo ? <ButtonLink href={demo}>działające demo</ButtonLink> : ''} 
                  </>
                }
                >
                  <div className="all-photos-container">
                {!frontmatter.photos ? <Loading /> : frontmatter.photos.map((el,i)=>
            <Image key={i} fluid={el.childImageSharp.fluid} style={{
              maxWidth: el.childImageSharp.fluid.aspectRatio * 85 +'vh'
            }} alt={frontmatter.alt ? frontmatter.alt : frontmatter.title} className="album-picture site-picture"/>
    )}</div>
                </ContentSection>
        })}
    </Layout>
    );
}